import React, { createContext, useContext, useEffect, useState } from 'react';
import { japaneseErrorMap } from '@/src/zod/japaneseErrorMap';
import { z } from 'zod';
import { IconContext } from 'react-icons';
import { PiCaretRightBold, PiList, PiUser } from 'react-icons/pi';
import { NoticeType } from 'antd/es/message/interface';
import {
  Layout,
  Menu,
  MenuProps,
  Space,
  Typography,
  notification,
  Flex,
} from 'antd';
import { t } from 'i18next';
import { Content, Header } from 'antd/es/layout/layout';
import {
  indexWorkInstructionPath,
  newSessionPath,
  indexWorkTaskPath,
  dailyPath,
} from '@/src/get_routes';
import dayjs from 'dayjs';
import { formatDateForRequest } from '@/src/utils';
import {
  buildApi,
  getCurrentEmployee,
  DeviseApi,
  getPermissions,
} from '@/src/rails_helper';
import ReactDOM from 'react-dom/client';
import 'normalize.css';
import '@/stylesheets/antd_overrides.css';
import { NotificationInstance } from 'antd/es/notification/interface';
import 'dayjs/locale/ja';
import 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import weekday from 'dayjs/plugin/weekday';
import { GlobalTheme } from './GlobalTheme';
import { GlobalStyle } from './GlobalStyle';
import { VersionNotificationModalIcon } from './VersionNotificationModalIcon';
import { ICON_SIZES, SPACING_PX } from '@/src/constants/desktop/styleConstants';
import { getBugsnagErrorBoundary } from '@/src/bugsnag';

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(weekday);

z.setErrorMap(japaneseErrorMap);
dayjs.locale('ja');

type Props = {
  children: React.ReactNode;
  currentMenuKey?: string;
};

type RootContextType = {
  message: FlashMessage | undefined;
  api: NotificationInstance | undefined;
};

type FlashMessage = {
  title?: string;
  message: string;
  type: NoticeType;
};

const RootContext = createContext<RootContextType>({
  message: undefined,
  api: undefined,
});

const BugsnagErrorBoundary = getBugsnagErrorBoundary();

const Root = ({ children, currentMenuKey }: Props) => {
  // TODO: このケースがありうるか検証して不要であれば削除する（多分、ここが最初以外に再レンダリングされることはなさそうなので不要だと思う）
  const employee = getCurrentEmployee();
  if (!employee) {
    location.href = newSessionPath();
    return <></>;
  }

  const [api, contextHolder] = notification.useNotification();

  const [message, setMessage] = useState<FlashMessage | undefined>();

  useEffect(() => {
    try {
      if (sessionStorage.getItem('flash')) {
        setMessage(JSON.parse(sessionStorage.getItem('flash') || ''));
        sessionStorage.removeItem('flash');
      }
    } catch (e) {
      console.warn('Failed to parse flash message from session storage');
      console.warn(e);
    }
  }, []);

  function handleManagement() {
    location.assign(
      document
        .getElementById('fukuma-management-path')
        ?.getAttribute('data-management-path') ?? ''
    );
  }

  function handleSignout() {
    buildApi(DeviseApi)
      .destroySession()
      .finally(() => {
        location.href = newSessionPath();
      });
  }

  const mainMenuItems: MenuProps['items'] = [
    {
      label: t('components.root.work_instruction'),
      icon: <PiCaretRightBold style={{ fontSize: ICON_SIZES.small }} />,
      key: 'work_instruction',
      onClick: () => location.assign(indexWorkInstructionPath()),
    },
    {
      label: t('components.root.work_task'),
      icon: <PiCaretRightBold style={{ fontSize: ICON_SIZES.small }} />,
      key: 'work_task',
      onClick: () => {
        location.assign(indexWorkTaskPath());
      },
    },
    {
      label: t('components.root.work_schedule'),
      icon: <PiCaretRightBold style={{ fontSize: ICON_SIZES.small }} />,
      key: 'work_schedule',
      onClick: () =>
        location.assign(dailyPath(formatDateForRequest(dayjs()) ?? '')),
    },
  ];

  const hasManagementPermission = () => {
    const permissions = getPermissions() ?? [];
    const managementPermissions = [
      'isAllowedManageClient',
      'isAllowedManageClientContact',
      'isAllowedManageGroup',
      'isAllowedManageEmployee',
      'isAllowedManageDepotSchedule',
      'isAllowedManageDistrict',
      'isAllowedManageRole',
      'isAllowedManageConstructionType',
      'isAllowedManageWebApiKey',
    ];
    return managementPermissions.some((permission) => permissions[permission]);
  };

  const getHamburgerMenuItems = () => {
    const items = [
      {
        key: 'sign_out',
        label: t('components.root.sign_out'),
        onClick: handleSignout,
      },
    ];
    if (hasManagementPermission() || getCurrentEmployee()?.isAdmin) {
      items.unshift({
        key: 'management',
        label: t('components.root.management'),
        onClick: handleManagement,
      });
    }
    return items;
  };

  const hamburgerMenuItems: MenuProps['items'] = [
    {
      key: 'setting',
      icon: <PiList style={{ fontSize: ICON_SIZES.medium }} />,
      children: getHamburgerMenuItems(),
    },
  ];

  return (
    <>
      <IconContext.Provider
        value={{
          className: 'anticon',
          style: { verticalAlign: '-0.2em', cursor: 'inherit' },
        }}
      >
        {contextHolder}
        <Layout>
          <Header
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Flex style={{ flexGrow: 1 }}>
              <Typography.Text
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                  marginRight: SPACING_PX.md,
                }}
              >
                <Flex align="center" gap={SPACING_PX.xs}>
                  <img src="/logo.png" alt="logo" width={ICON_SIZES.medium} />
                  {t('components.root.application_name')}
                </Flex>
              </Typography.Text>
              <Menu
                items={mainMenuItems}
                selectedKeys={currentMenuKey ? [currentMenuKey] : []}
                mode="horizontal"
                theme="dark"
                style={{ flexGrow: 1 }}
              ></Menu>
            </Flex>
            <Space size="middle">
              <Space>
                <PiUser
                  style={{ fontSize: ICON_SIZES.medium, color: 'white' }}
                />
                <Typography.Text style={{ color: 'white' }}>
                  {employee?.name}
                </Typography.Text>
              </Space>
              <VersionNotificationModalIcon />
              <Menu
                mode="horizontal"
                theme="dark"
                items={hamburgerMenuItems}
              ></Menu>
            </Space>
          </Header>
          <Content
            style={{ padding: SPACING_PX.md, minHeight: 'calc(100vh - 64px)' }}
          >
            <RootContext.Provider value={{ message, api }}>
              {children}
            </RootContext.Provider>
          </Content>
        </Layout>
      </IconContext.Provider>
    </>
  );
};

/**
 * Rootコンポーネント内部にエントリポイントを作成する
 * @param App 使用するエントリポイントのコンポーネントを指定する
 */
export function createRoot(App: React.ComponentType, currentMenuKey?: string) {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <GlobalStyle />
      <GlobalTheme>
        <Root currentMenuKey={currentMenuKey}>
          {BugsnagErrorBoundary ? (
            <BugsnagErrorBoundary>
              <App />
            </BugsnagErrorBoundary>
          ) : (
            <App />
          )}
        </Root>
      </GlobalTheme>
    </React.StrictMode>
  );
}

export const useRootContext = () => {
  return useContext(RootContext);
};

/**
 * FlashMessageを設定する
 * このFlashMessageは次の遷移先で使用する
 * 取得方法はRoot.tsxのuseFlashMessageを使用する
 * @param message 設定するタイトルとメッセージとタイプを指定する
 */
export function setFlashMessage(message: FlashMessage) {
  // TODO: 使うかと思ってtitleを定義していたが、使用していないようならどこかのタイミングで削除する
  message.title = message.title || '';
  sessionStorage.setItem('flash', JSON.stringify(message));
}
